import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import Slide from "@material-ui/core/Slide"
import FormControl from "@material-ui/core/FormControl"
import emailjs from "emailjs-com"
import Reaptcha from "reaptcha"

function SlideTransition(props) {
  return <Slide {...props} direction="up" />
}

const useStyles = theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  contatti: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
})

class Contatti extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      comment: "",
      mail: "",
      open: false,
      recaptchaValid: false,
    }
    this.captcha = null
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleMailChange = this.handleMailChange.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  // load on callback verify
  verifyCallback(event) {
    this.setState(state => {
      return {
        recaptchaValid: !state.recaptchaValid,
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Layout title="Contatti">
        <div className={classes.root}>
          <section className={classes.banner}>
            <Container className={classes.bannerContent}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <h1
                    style={{
                      color: "#fe5f55",
                      fontFamily: "Montserrat",
                      fontDisplay: "swap",
                    }}
                  >
                    Contatti
                  </h1>
                  <p
                    style={{
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontDisplay: "swap",
                    }}
                  >
                    Vuoi avere informazioni aggiuntive sulle squadre? <br />
                    <br />
                    Vuoi contribuire con ulteriori informazioni riguardo alla
                    storia di Jesolo San Dona' Basket?? <br />
                    <br />
                    Questo è il posto giusto per farlo!!!
                    <br />
                    Compila i campi qui in basso con il tuo nome, la tua mail e
                    invia il tuo messaggio
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
              </Grid>
            </Container>
          </section>
          <section className={classes.contatti}>
            <Container>
              <Grid
                style={{
                  marginTop: 10,
                  marginLeft: 50,
                  marginRight: 50,
                  maxWidth: "75%",
                }}
              >
                <form name="contact-recaptcha" onSubmit={this.handleSubmit}>
                  <Grid item xs={12}>
                    <TextField
                      id="nome-cognome"
                      label="Nome Cognome"
                      placeholder="Per es. Mario Rossi"
                      helperText="Inserite il vostro nome e il vostro cognome"
                      autoComplete="nome"
                      onChange={this.handleNameChange}
                      value={this.state.name}
                      fullWidth
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      autoComplete="email"
                      placeholder="Per es. mario.rossi@gmail.com"
                      helperText="Inserite la vostra email"
                      onChange={this.handleMailChange}
                      value={this.state.mail}
                      fullWidth
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="commento"
                      label="Messaggio"
                      placeholder="Scrivi qui il tuo messaggio"
                      helperText="Contattaci qui"
                      onChange={this.handleCommentChange}
                      value={this.state.comment}
                      fullWidth
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <FormControl variant="outlined" required>
                      <Reaptcha
                        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                        onVerify={this.verifyCallback}
                        ref={e => (this.captcha = e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item style={{ marginTop: 20 }} xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Invia
                    </Button>
                    <Snackbar
                      open={this.state.open}
                      onClose={this.toggleModal}
                      TransitionComponent={SlideTransition}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.toggleModal}
                        severity="success"
                      >
                        Grazie per averci contattato, ti risponderemo al più
                        presto
                      </MuiAlert>
                    </Snackbar>
                  </Grid>
                </form>
              </Grid>
            </Container>
          </section>
        </div>
      </Layout>
    )
  }

  toggleModal() {
    this.setState(state => {
      return {
        open: !state.open,
        name: "",
        comment: "",
        mail: "",
      }
    })
    setTimeout(function () {
      window.location.reload(false)
    }, 5000)
  }

  handleCommentChange(event) {
    this.setState({ comment: event.target.value })
  }
  handleNameChange(event) {
    this.setState({ name: event.target.value })
  }
  handleMailChange(event) {
    this.setState({ mail: event.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.state.recaptchaValid !== true) {
      this.setState(state => {
        return {
          visibility: !state.visibility,
        }
      })
    } else {
      if (
        this.state.comment !== "" &&
        this.state.mail !== "" &&
        this.state.name !== ""
      ) {
        emailjs
          .send(
            "default_service",
            process.env.GATSBY_EMAILJS_TEMPLATE_CONTATTI,
            {
              message_html: this.state.comment,
              from_name: this.state.name,
              reply_to: this.state.mail,
            },
            process.env.GATSBY_EMAILJS_USERID
          )
          .catch(err =>
            console.error(
              "Oh well, you failed. Here some thoughts on the error that occured:",
              err.text
            )
          )
        this.toggleModal()
        this.captcha.reset()
      }
    }
  }
}

Contatti.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(useStyles)(Contatti)
